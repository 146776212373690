// colors
$dark-dark-purple: #1c183c;
$dark-purple: #581e68;
$leidos-violet: #201747;
$leidos-purple: #6f2e76;
$default-background: #f0f2f4;
$leidos-green: #257080;
$graduated-background: transparent
  linear-gradient(180deg, $dark-dark-purple 0%, $dark-purple 100%) 0% 0%
  no-repeat padding-box;
$error-background: transparent radial-gradient(#581e68 25%, #1c183c 125%);

// spacing
$header-height: 62px;
$under-header-padding: 34px;
$footer-bar: 16px; // 53
$footer-overlap: 37px;
$footer-padding: 0px; // 8
$nav-element-height: 46px;
$nav-element-padding: 24px;
$nav-element-highlighted-padding: 8px;
$page-left-padding: 20px;
$page-title-padding-bottom: 24px;

// card formatting
$card-border-radius: 8px !important; // !important required in order to override more-specific style from mui

// miscellaneous
$switch-to-mobile-width: 799px;
