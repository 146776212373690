@use '../variables';

.header {
  height: variables.$header-height;
  background-color: variables.$leidos-violet;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05) !important;
  padding: 0px 15px;
  box-sizing: border-box;
}

.appSignature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconStyle {
  padding-left: 20px;
  padding-right: 12px;
  vertical-align: middle;
}
