.main {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;

  display: flex;
  min-height: 100%;
  box-sizing: border-box;
}
