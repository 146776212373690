@use '../variables';

.referralHub {
  height: calc(100vh - variables.$header-height);
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: variables.$default-background;
  gap: 16px;
  padding: 36px;
  box-sizing: border-box;
}

.referrals {
  height: 100%;
  padding-bottom: 36px;
  overflow: hidden;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.button {
  border-color: variables.$leidos-purple !important;
  color: variables.$leidos-purple !important;
  border-radius: 8px !important;
  text-transform: none;
}
