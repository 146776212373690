@use '../variables';

.referralDetails {
  height: calc(100vh - variables.$header-height);
  width: 100vw;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(7, 75px);
  background-color: variables.$default-background;
  gap: 16px;
  padding: 36px;
  box-sizing: border-box;
}

.scheduleButton {
  justify-self: end;
}

.cancelButton {
  grid-row: 8;
  grid-column: 1;
}

.resetButton {
  grid-row: 8;
  grid-column: 2;
  justify-self: center;
}
.addButton {
  grid-row: 8;
  grid-column: 2;
  justify-self: end;
}

.button {
  width: 140px;
  height: 40px;
  margin: 1rem 1rem 1rem 0rem;
  font-size: 16px !important;
  font-weight: 700 !important;
  background-color: #{variables.$leidos-purple} !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
}