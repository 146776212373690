@use '../variables';

.lastColumnPadding {
  tbody tr td:last-child {
    padding-right: 15px;
  }
}

.tableContainer {
  max-height: 440px;
  border-radius: #{variables.$card-border-radius};
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05) !important;
}

.tableHeaderText {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: white !important;
  background-color: variables.$leidos-purple !important;
}

.tableCell {
  font-size: 16px !important;
  white-space: nowrap;
}
