@use '../variables';

.card {
  border-radius: #{variables.$card-border-radius};
  margin-bottom: #{variables.$page-title-padding-bottom};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05) !important;
  max-width: 600px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  padding: 24px;
  box-sizing: border-box;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 32px;
}

.searchFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchButton {
  width: 140px;
  height: 40px;
  font-size: 16px !important;
  font-weight: 700 !important;
  background-color: #{variables.$leidos-purple} !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.searchBox {
  width: 320px;
  flex: 1 1 auto;
}

.searchIcon {
  color: blue;
}
